/* ==========================================================================
Accueil
========================================================================== */
body {
border-top: solid 3px $brand-second;
overflow-x: hidden;
background: url(../images/homeBG.jpg) top left no-repeat #fff;
// background-size: cover;

}
.header-accueil {
@extend .animated;
@extend .fadeInDown;
overflow: hidden;
padding: 5vh 0;
background: linear-gradient(45deg, rgba($text-color, .75), rgba($text-color, .35));;
// background: linear-gradient(45deg, rgba($brand-primary, .35), rgba($brand-tierce, .35));;
border-bottom: solid .3rem $body-bg;
box-shadow: $box-shadow;
}
.card {
margin-top: 20px;
}
.card-accueil {
@extend .card;
padding: 20px 20px 30px 20px !important;
li {
margin-bottom: 5px !important;
}
}

.logo-accueil {
@extend .animated;
@extend .fadeInUp;
animation-delay: .4s;

>img {
max-height: 10rem;
@extend .img-responsive;
@extend .center-block;
position: relative;
z-index: 1000;
}
}
.main-accueil {
min-height: 30vh;
// background-color: white;
}

.img-intro {
@extend .animated;
@extend .fadeInup;
@extend .img-responsive;
@extend .center-block;
// border-top: solid 3px $brand-primary;
border-bottom: solid 3px $brand-primary;
animation-delay: .7s;
margin: -2rem auto -4rem auto;
}
.delai-001 {
@extend .animated;
@extend .fadeInUp;
animation-delay: .3s;
}
.delai-002 {
@extend .animated;
@extend .fadeInUp;
animation-delay: .5s;
}
.delai-003 {
// @extend .img-responsive;
// @extend .center-block;
@extend .animated;
@extend .fadeInDown;
animation-delay: .7s;
}
.delai-004 {
@extend .animated;
@extend .fadeInDown;
animation-delay: .9s;
}
.delai-005 {
@extend .animated;
@extend .fadeInDown;
animation-delay: 1.3s;
}
.delai-006 {
@extend .animated;
@extend .fadeInUp;
animation-delay: 1.7s;
}
.delai-007 {
@extend .animated;
@extend .fadeInUp;
animation-delay: 1.9s;
}



.btn-accueil {
@extend .btn;
@extend .btn-lg;
@extend .btn-primary;
position: relative;
z-index: 1000;
margin: .5rem auto .5rem auto;
padding: 1.5rem;
// border-radius: $border-radius-large;
border-radius: 5rem;
font-size: 2rem !important;
text-shadow: .1rem .3rem .6rem rgba($brand-primary, .5);
border: solid 3px $body-bg !important;
box-shadow: $box-shadow;
color: white !important;
// background-color: darken($brand-primary, 10%) !important;

// height: 55px;

>img {
@extend .img-responsive;
@extend .center-block;
height: 9rem;
width: 9rem;
margin:-9rem -1rem 0 1rem;




}

// transition: transform ease-in .2s;
// border: solid 3px white !important;

&:hover {
// margin: 1rem auto 0 auto;
background-color: darken($brand-primary, 17%) !important;
// border: solid 3px $brand-primary !important;
transition: transform ease-in .2s;
box-shadow: 0px 4px 8px rgba($gray-darker, 0.7) !important;
letter-spacing: 2px;
>img {
margin:-8.5rem -1rem 0 1rem;
transition: transform ease-in .2s;
}
}

}

.footer-accueil {

@extend .text-center;
@extend .footer;
padding: 30px 0;
margin: 3rem 0 0 0;
border-top: solid 0px $brand-second;
position: relative;
z-index: 10 !important;




h1,.h1 {
// color: $brand-second;
// color: $body-bg;
}
}

.col-footer-accueil-left {
@extend .col-sm-6;
@extend .text-left;

@media (max-width: $screen-xs-max) {
text-align: center;
}
}

.col-footer-accueil-right {
@extend .col-sm-6;
@extend .text-right;

@media (max-width: $screen-xs-max) {
text-align: center;
padding-top: 20px;
}
}

.diapo-bandeau {
@extend .animated;
@extend .fadeInLeft;
animation-delay: .2s;
border: solid 3px $brand-second;
position :relative;
box-shadow: $box-shadow;
img {
@extend .img-responsive;
max-height: 30rem !important;
}
}

.texte-primary {
color: $brand-primary;
}
.texte-second {
color: $brand-second;
}
.texte-massif {
font-size: 3rem;
text-shadow: .1rem .3rem .6rem rgba($body-bg, .5);

}
.un-peu-fat {
font-size: 2rem;
}
.moins-fat {
font-size: 1.3rem;
}

.bloccueil {
margin: 12vh auto;
// max-width: 60rem;
overflow: hidden;
position: relative;
z-index: 100;
@extend .animated;
@extend .fadeInDown;
@extend .container;
background: rgba($body-bg, .75);
background: white;
// border: solid 5px white;
box-shadow: $box-shadow;
padding: 0rem;


.content-bloccueil {
display: flex;
flex-direction: column;

@media(min-width: $screen-md-min) {
flex-direction: row;
}
}

.side-bloccueil-left {
padding: 1.7rem;
@extend .text-center;
width: 100%;

@media(min-width: $screen-md-min) {
width: 35%;
}
}

.side-bloccueil-right {
// border-left: solid 3px $brand-second;
overflow: hidden;
margin: 0;
padding: 0;
width: 100%;

@media(min-width: $screen-md-min) {
width: 65%;
}

>img {
@extend .center-block;
@extend .img-responsive;
width: 40%;
@media(min-width: $screen-md-min) {
width: 100%;
}
}
}
}


.parallax-window-accueil {
@extend .parallax-window;
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items: center;
min-height: 95vh;
text-align: center;
overflow: hidden;
padding: 30vh 25vw;
border-bottom: solid 5px $brand-second;

>a {
z-index: 100;
@extend .superside;
font-size: 5rem;
display: inline-block;
color: $brand-second;
text-shadow: $box-shadow;
position: relative;
transition: all ease-in-out .3s;
text-shadow: 0 1rem 2rem rgba($text-color, .5);

>img {
@extend .img-responsive;
max-width: 60%;
margin: 0 auto;
transition: all linear .3s;
}



&.wp1::after {
display: none;
}

&:hover::after {
width: 50%;
opacity: 1;
}

@media(min-width: $screen-sm-min) {
font-size: 65px;

&:hover {
// font-size: 4.5rem !important;
transition: all linear .3s;

letter-spacing: 2px;
color: $brand-tierce;
opacity: .95;

>img {
transform: scale(1.3);
}
}
}

@media(min-width: $screen-md-min) {
font-size: 8rem;
}
}
}
/* ==========================================================================
Waypoints
========================================================================== */

.wp1, .wp2, .wp3, .wp4, .wp5, .wp6, .wp7, .wp8 {
}

.fadeInUpBig {
visibility: visible;
}


.texte-dessus{
@extend .superside;
@extend .text-center;
@extend .un-peu-fat;
color: $text-color;
position: absolute;
background-color: rgba($body-bg, .8);
bottom: 40%;
left: 15px;
right: 15px;
padding: 1rem;
} 