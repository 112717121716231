/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	position: relative;
	background: linear-gradient(45deg, rgba($brand-second, .25), rgba($text-color, .75));;
	// @extend .text-center;
	
	.content-header {
		@extend .container-max;
		padding: 5rem;
	}
	
	.logo-header {
		
		>img {
			@extend .img-responsive;
			display: inline-block;
			// width: 15rem;
		}
	}
}